export const API_BASE_URL = `${window.location.protocol}//${window.location.hostname}` + '/api/v1' // (window.location.port ? ':' + window.location.port: '') + '/api'

export default {

    /**
     * Auth 
     */    
    SIGNIN_URL: `${API_BASE_URL}/auth/login`,
    SIGNUP_URL: `${API_BASE_URL}/auth/signup`,
    CHECK_USER_ROLE_URL: `${API_BASE_URL}/auth/checkrole`,
    CHANGE_PASSWORD_URL: `${API_BASE_URL}/auth/changepass`,
    UPDATE_ACCOUNT_URL: `${API_BASE_URL}/auth/updateaccount`,
    FORGOT_PASSWORD_URL: `${API_BASE_URL}/auth/forgotpass`,
    
    /**
     * Super Admin
     */
    SUPER_ADMIN_URL: `${API_BASE_URL}/superadmin`,


    /**
     * User
     */
    USER_URL: `${API_BASE_URL}/user`,

    /**
     * Device
     */
    DEVICE_URL: `${API_BASE_URL}/device`,

    /**
     * Terminal
     */
    /*CHANNEL_LIST_URL: `${API_BASE_URL}/channel`,
    CHANNEL_ADD_URL: `${API_BASE_URL}/channel/add`,
    CHANNEL_UPDATE_URL: `${API_BASE_URL}/channel/update`,
    CHANNEL_REMOVE_URL: `${API_BASE_URL}/channel/delete`,
    CHANNEL_GET_URL: `${API_BASE_URL}/channel/get`,
    CHANNEL_GET_RECEIVERS_URL: `${API_BASE_URL}/channel/receivers`,
     */

    /**
     * Account
     */
    /*REPORT_LIST_URL: `${API_BASE_URL}/report`*/
    
}