import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import Routes from './routes';
import SignIn from './auth/signin';

const RestrictedRoute = ({component: Component, user, ...rest}) =>
    <Route {...rest} render={props =>
        user ? <Component {...props} /> : <Redirect to={{ pathname: '/signin', state: {from: props.location} }} />}
    />;

class Container extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
         
        }

    }

    render() {

        return (
           <Switch>
               <Route path='/signin' component={SignIn}/>
               
               <RestrictedRoute path={`${this.props.match.url}`} user={this.props.user} component={Routes}/>
           </Switch>
        );

    }

}


const mapStateToProps = (reducer) => {

    const { user } = reducer;

    return {user}

};


export default connect(mapStateToProps, {})(Container);