import React from 'react';
import store from 'store'
import {withRouter, Redirect, NavLink} from 'react-router-dom'
import { Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    Site,
    Page,
    RouterContextProvider,
    Form,
    Button
} from 'tabler-react';

import Modal from 'react-responsive-modal';
import 'react-responsive-modal/src/styles.css';

import { connectToMQTT, subscribeDevices, unsubscribeDevices } from 'mqtt-helper'; 
import DeviceList from './devices';

import { API_BASE_URL } from 'apis/apiurl';
import { setMQTTDetails, setDevices, setTransactions } from 'store/actions';


import 'sass/index.scss';
import cogoToast from 'cogo-toast';

const navBars = () => {
    return [{
        value: 'Devices',
        to: '/devices',
        icon: 'hard-drive',
        LinkComponent: withRouter(NavLink),
    }];
}

class Routes extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            show_magic_modal: false,
            show_help_modal: false,

            mqtt_server_ip: "",
            mqtt_server_port: "",
            mqtt_server_username: "",
            mqtt_server_password: "",
            topic_base_path: "",
            mqtt_websocket_secure: false
        }

        this.mqttClient = null;
    }

    componentDidMount = () => {

        let newItem = document.createElement('div');
        newItem.setAttribute('id', 'connect-status');

        let i_element = document.createElement("i");
        i_element.setAttribute('class', 'fa fa-circle mr-2');
        i_element.setAttribute('style', 'font-size: 11px;color: grey;');
        
        let label_element = document.createElement("label");
        label_element.setAttribute('class', 'mb-0');
        label_element.setAttribute('style', 'font-size: 12px;font-weight:bold;');
        label_element.appendChild(document.createTextNode("Disconnected"));

        newItem.appendChild(i_element);
        newItem.appendChild(label_element);

        let rightDropDown = document.querySelector('.page-main>.header>.container>.d-flex>.ml-auto');
        rightDropDown.setAttribute('class', rightDropDown.getAttribute('class') + ' align-items-center');
        rightDropDown.insertBefore(newItem, rightDropDown.childNodes[0]);
        

        const { mqtt_server } = this.props;

        if (mqtt_server.ip) {
            this.connectToMQTTServer(mqtt_server);
        }
    }

    connectToMQTTServer = (mqtt_server) => {
        const clientId = 'mqttjs_' + Math.random().toString(16).substr(2, 8)

        const host = `${mqtt_server.ws_secure?'wss':'ws'}://${mqtt_server.ip}`;
        
        const options = {
            port: mqtt_server.port,
            username: mqtt_server.username,
            password: mqtt_server.password,
            keepalive: 60,
            clientId: clientId,
            protocolId: 'MQTT',
            protocolVersion: 4,
            // clean: false,
            reconnectPeriod: 1000,
            connectTimeout: 30 * 1000,
            // will: {
            //     topic: 'topicpath/test92/enabled',
            //     payload: 'Connection Closed abnormally..!',
            //     qos: 1,
            //     retain: true
            // }
        };

        this.mqttClient = connectToMQTT(host, options);

        if (!this.mqttClient) return;

        this.mqttClient.on('connect', () => {
            console.log('Connected');

            document.querySelector('#connect-status > i').setAttribute('style', 'font-size: 11px;color: green;');
            document.querySelector('#connect-status > label').innerText = 'Connected';

            this.subscribeDevices();
        });
        
        this.mqttClient.on('error', (err) => {
            console.error('Connection error: ', err);
            this.mqttClient.end();

            document.querySelector('#connect-status > i').setAttribute('style', 'font-size: 11px;color: grey;');
            document.querySelector('#connect-status > label').innerText = 'Disconnected';
        });

        this.mqttClient.on('reconnect', () => {
            console.log('Reconnecting');

            document.querySelector('#connect-status > i').setAttribute('style', 'font-size: 11px;color: grey;');
            document.querySelector('#connect-status > label').innerText = 'Reconnecting...';
        });

        this.mqttClient.on('message', (topic, message) => {
            const comps = topic.split("/");
            if (comps.length < 3) return;
            if (comps[0] !== this.props.mqtt_server.basepath) return;

            const regID = comps[1];
            const action = comps[2];
            message = message.toString();

            const { transactions } = this.props;
            transactions.splice(0, 0, {
                topic, message, date: new Date()
            });
            this.props.setTransactions(transactions);
            
            if (comps.length === 6 && action === 'command' && comps[3] === 'start' && comps[4] === 'res') {
                const requestId = comps[6];
                this.handleAction(regID, action, message, requestId);
                
            } else if (action === 'enabled') {
                this.handleAction(regID, action, message);
            }
            
        });
    }

    handleAction = (regID, action, message) => {
        if (action === 'enabled') {

            const { devices } = this.props;

            devices.forEach(device => {
                if (device.regID === regID) {
                    device.enabled = (message === '1');
                }
            });

            this.props.setDevices(JSON.parse(JSON.stringify(devices)));

        } else if (action === 'command') {
            const { devices } = this.props;

            devices.forEach(device => {
                if (device.regID === regID) {
                    device.last_transaction = message;
                }
            });

            this.props.setDevices(JSON.parse(JSON.stringify(devices)));
        }
    }

    updateConfigDetails = (event) => {
        event.preventDefault();

        const {
            mqtt_server_ip, 
            mqtt_server_port,
            mqtt_server_username,
            mqtt_server_password,
            topic_base_path,
            mqtt_websocket_secure
        } = this.state;

        this.props.setMQTTDetails({
            ip: mqtt_server_ip, 
            port: mqtt_server_port,
            username: mqtt_server_username,
            password: mqtt_server_password,
            basepath: topic_base_path,
            ws_secure: mqtt_websocket_secure
        });

        this.closeConfigModal();

        if (this.mqttClient) {
            unsubscribeDevices(topic_base_path, this.props.devices, (error) => {
                if (error) {
                    // cogoToast.error(error);
                }
                this.mqttClient.end();
            });
        }

        document.querySelector('#connect-status > i').setAttribute('style', 'font-size: 11px;color: grey;');
        document.querySelector('#connect-status > label').innerText = 'Disconnected';

        this.connectToMQTTServer({
            ip: mqtt_server_ip, 
            port: mqtt_server_port,
            username: mqtt_server_username,
            password: mqtt_server_password,
            basepath: topic_base_path,
            ws_secure: mqtt_websocket_secure
        });
    }

    showConfigModal = () => {
        const { mqtt_server } = this.props;

        this.setState({
            show_magic_modal: true,
            mqtt_server_ip: mqtt_server.ip,
            mqtt_server_port: mqtt_server.port,
            mqtt_server_username: mqtt_server.username,
            mqtt_server_password: mqtt_server.password,
            topic_base_path: mqtt_server.basepath,
            mqtt_websocket_secure: mqtt_server.ws_secure
        });
    }

    closeConfigModal = () => {
        this.setState({
            show_magic_modal: false
        })
    }

    subscribeDevices = () => {
        const { devices, mqtt_server } = this.props;

        subscribeDevices(mqtt_server.basepath, devices, (error) => {
            if (error) {
                cogoToast.error(error);
            }
        });
    }

    signOut = () => {
        if (this.mqttClient) {
            this.mqttClient.end();
        }

        store.remove('user');
        window.location.href = "/signin";
    }

    showHelpModal = () => {
        this.setState({
            show_help_modal: true
        });
    }

    closeHelpModal = () => {
        this.setState({
            show_help_modal: false
        });
    }

    render() {
        const { user } = this.props;

        const { show_help_modal, show_magic_modal, mqtt_server_ip, mqtt_server_port, 
                mqtt_server_username, mqtt_server_password, topic_base_path, mqtt_websocket_secure } = this.state;

        this.accountDropdownProps = (user) => ({
            dangerouslySetInnerHTML: '<h2>safddsaf</h2>',
            avatarURL: `${API_BASE_URL}/user/${user.user._id}/photo`,
            name: `${user.user.username}`,
            description: `${user.user.email}`,
            options: [
                // { icon: "user", value: "My Account", onClick: () => { window.location.href = "/myprofile"; } },
                // { icon: "lock", value: "Change Password", onClick: () => {window.location.href = "/changepass";}},
                { icon: "settings", value: "Configure", onClick: this.showConfigModal },
                { icon: "help-circle", value: "Help", onClick: this.showHelpModal },
                { isDivider: true },
                // { icon: "help-circle", value: "Need help?" },
                { icon: "log-out", value: "Sign out", onClick: () => this.signOut() },
            ],
        });

        return (
            <div>
                <Site.Wrapper
                    headerProps={{
                        href: "/devices",
                        alt: "Loop",
                        imageURL: "/images/logo.png",
                        accountDropdown: this.accountDropdownProps(user),
                    }}
                    navProps={{ itemsObjects: navBars() }}
                    routerContextComponentType={withRouter(RouterContextProvider)}>
                    
                    <Switch>
                        <Page.Content>
                            <Switch>
                                <Route path='/devices' component={DeviceList} />

                                <Route path='/*' render={() => <Redirect to='/devices' />} />
                            </Switch>
                        </Page.Content>
                    </Switch>
                </Site.Wrapper>

                <Modal open={show_magic_modal} onClose={this.closeConfigModal} center
                    showCloseIcon={false} closeOnEsc={false} closeOnOverlayClick={false}
                    classNames={{                        
                        modal: 'customModal'
                    }}>

                    <h2>Configure MQTT Server</h2>                    

                    <Form onSubmit={this.updateConfigDetails}>
                        <Form.Group label="MQTT Host URL">
                            <input className="form-control" type="text" required value={ mqtt_server_ip }
                                        onChange={(event) => this.setState({ mqtt_server_ip: event.target.value })} ></input>
                        </Form.Group>

                        <Form.Group label="MQTT Server Port">
                            <Form.Input type="number" min="0" required value={ mqtt_server_port } 
                                onChange={(event) => this.setState({ mqtt_server_port: event.target.value })} />
                        </Form.Group>

                        <Form.Group label="MQTT Server Username">
                            <Form.Input type="text" required value={ mqtt_server_username } 
                                onChange={(event) => this.setState({ mqtt_server_username: event.target.value })} />
                        </Form.Group>

                        <Form.Group label="MQTT Server Password">
                            <Form.Input type="password" required value={ mqtt_server_password } 
                                onChange={(event) => this.setState({ mqtt_server_password: event.target.value })} />
                        </Form.Group>

                        <Form.Group label="Base Topic Path">
                            <Form.Input type="text" required value={ topic_base_path } 
                                onChange={(event) => this.setState({ topic_base_path: event.target.value })} />
                        </Form.Group>

                        <Form.Checkbox 
                            label="WebSocket Secure"
                            checked={mqtt_websocket_secure}
                            onChange={(event) => this.setState({ mqtt_websocket_secure: event.target.checked })} />

                        <div className="d-flex align-items-center justify-content-between mt-5">
                            <Button type="button" color="secondary" onClick={this.closeConfigModal}>Cancel</Button>
                            <Button color="primary">Connect</Button>
                        </div>
                    </Form>
                </Modal>

                <Modal open={show_help_modal} onClose={this.closeHelpModal} center
                    showCloseIcon={false} closeOnEsc={false} closeOnOverlayClick={false}
                    classNames={{                        
                        modal: 'customModal'
                    }}>

                    <h2>Help</h2>

                    <div>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                    </div>

                    <Button.List align="center" className="mt-4">
                        <Button type="button" color="secondary" onClick={this.closeHelpModal}>Close</Button>
                    </Button.List>
                </Modal>
            </div>
            
        );
    }
}

const mapStateToProps = (reducer) => {
    const { isLoader, user, mqtt_server, devices, transactions } = reducer;
    return {isLoader, user, mqtt_server, devices, transactions }
};

export default withRouter(connect(mapStateToProps, {
    setMQTTDetails, setDevices, setTransactions
})(Routes));