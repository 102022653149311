import {
    UPDATE_USER,
    SET_DEVICES,
    SET_MQTT_DETAILS,
    SET_TRANSACTIONS
} from '../actionTypes';

export function updateUser(data) {
    return {
        type: UPDATE_USER,
        payload: data
    };
}

export function setDevices(data) {
    return {
        type: SET_DEVICES,
        payload: data
    }
}

export function setMQTTDetails(data) {
    return {
        type: SET_MQTT_DETAILS,
        payload: data
    }
}

export function setTransactions(data) {
    return {
        type: SET_TRANSACTIONS,
        payload: data
    }
}
