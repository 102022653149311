import {
    UPDATE_USER,
    SET_DEVICES,
    SET_MQTT_DETAILS,
    SET_TRANSACTIONS
} from '../actionTypes';
import store from 'store';


const INIT_STATE = {
    user: store.get('user'),
    devices: store.get('devices')?store.get('devices'):[],
    mqtt_server: store.get('mqtt_server')?store.get('mqtt_server'):{},
    transactions: []
};

export default (state = INIT_STATE, action) => {

    switch (action.type) {

        case UPDATE_USER:

            store.set('user', action.payload);
            
            return {
                ...state,
                user: action.payload,
            };

        case SET_DEVICES:

            store.set('devices', action.payload);
            
            return {
                ...state,
                devices: action.payload,
            };

        case SET_MQTT_DETAILS:
            
            store.set('mqtt_server', action.payload);

            return {
                ...state,
                mqtt_server: action.payload
            }

        case SET_TRANSACTIONS:
            
            return {
                ...state,
                transactions: action.payload
            }

        default:
            return state;

    }

}