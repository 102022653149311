import mqtt from 'mqtt';

let client;

export const connectToMQTT = (host, options) => {
    try {
        client = mqtt.connect(host, options);
        return client;

    } catch (e) {
        console.log(e);
        return null;
    }
}

export const subscribeDevices = (basePath, devices, callback) => {

    if (client && client.connected) {
        const topics = [];

        devices.forEach(device => {
            topics.push(`${basePath}/${device.regID}/enabled`)
    
            if (device.started && device.requestId) {
                topics.push(`${basePath}/${device.regID}/command/start/res/${device.requestId}`);
            }
        });
    
        if (topics.length === 0) {
            return callback();
        }

        client.subscribe(topics, (err) => {
            if (err) {
                console.log(err);
                callback('Error occurred to subscribe devices');
            }
        });
    } else {
        callback('MQTT connection disconnected');
    }
}

export const unsubscribeDevices = (basePath, devices, callback) => {
    if (client && client.connected) {
        const topics = [];

        devices.forEach(device => {
            topics.push(`${basePath}/${device.regID}/enabled`)
    
            if (device.started && device.requestId) {
                topics.push(`${basePath}/${device.regID}/command/start/res/${device.requestId}`);
            }
        });
    
        if (topics.length === 0) {
            return callback();
        }
        
        client.unsubscribe(topics, (err) => {
            if (err) {
                console.log(err);
                callback('Error occurred to unsubscribe devices');
            }
        });
    } else {
        callback('MQTT connection disconnected');
    }
}

export const subscribeDevice = (basePath, device, callback) => {
    if (client && client.connected) {
        client.subscribe([`${basePath}/${device.regID}/enabled`], (error) => {
            
            if (error) {
                callback('Error occurred to subscribe device');
            }
        });

    } else {
        callback('MQTT connection disconnected');
    }
}

export const unsubscribeDevice = (basePath, device, callback) => {
    if (client && client.connected) {

        let topics = [`${basePath}/${device.regID}/enabled`];

        if (device.started) {
            topics.push(`${basePath}/${device.regID}/command/start/res/${device.requestId}`);
        }

        client.unsubscribe(topics, (error) => {
            if (error) {
                callback('Error occurred to unsubscribe device');
            }
        });

    } else {
        callback('MQTT connection disconnected');
    }
}

export const startRequest = (basePath, device, requestId, callback) => {
    if (client && client.connected) {

        const topic = `${basePath}/${device.regID}/command/start/req`;

        client.publish(topic, JSON.stringify({ reqId: requestId, credit: Math.floor(device.amount * 100) }),
            { qos: 1, retain: false },
            (err) => {
                if (err) {
                    callback('Failed to start request');
                } else {
                    callback(null, requestId);

                    client.subscribe(`${basePath}/${device.regID}/command/start/res/${requestId}`, (error) => {
                        console.log(error);
                    });
                }
            }
        );
    } else {
        callback('MQTT connection disconnected')
    }
}

export const stopRequest = (basePath, device, requestId, callback) => {
    if (client && client.connected) {

        const topic = `${basePath}/${device.regID}/command/stop/req`;

        client.publish(topic, JSON.stringify({ reqId: requestId }),
            { qos: 1, retain: false },
            (err) => {
                if (err) {
                    callback('Failed to stop request');
                } else {
                    callback(null);
                }
            }
        );
    } else {
        callback('MQTT connection disconnected')
    }
}