import React from 'react';
import { connect } from 'react-redux';
import './index.scss'
import {
    Form,
    FormTextInput,    
    Button
} from 'tabler-react';
import "tabler-react/dist/Tabler.css";

import cogoToast from "cogo-toast";

import { updateUser } from 'store/actions'


class SignIn extends React.Component {

    constructor (props) {

        super (props);

        this.state = {
            bLoading: false
        }
    }

    updateForm = evt => {
        this.setState({
            [evt.target.name]: evt.target.value,
        });
    };
    
    handleLogin = (event) => {
        event.preventDefault();
        const { email, password } = this.state;

        if (email.toLowerCase() === 'admin@qibamdb.com' && password === 'Q1BaDeM0') {
            this.props.updateUser({
                user: {
                    email: 'admin@qibamdb.com',
                    username: 'Admin'
                },
                token: 'sampletoken'
            });
            this.props.history.push('/devices')
        } else {
            cogoToast.error("Invalid email or password!");
        }
    }
    
    render () {
        const { bLoading } = this.state;

        return (

            <div id="login" className="d-flex justify-content-center align-items-center">
                <div className="login_wrapper px-2 px-md-5 py-2 py-md-5">
                    <div className="px-2 px-md-5 py-2 py-md-5">
                        <h1 className="big-logo">Qiba MDB to MQTT<br />Demo Tool</h1>
                        <Form
                            onSubmit={this.handleLogin}
                            method="POST">

                            <FormTextInput
                                name="email"
                                label="Email"
                                type="email"
                                placeholder="Enter email"
                                onChange={this.updateForm}
                                error=""
                                required />

                            <FormTextInput
                                name="password"
                                type="password"
                                label="Password"
                                placeholder="Password"
                                minlength="8"
                                onChange={this.updateForm}
                                required />

                            <Form.Footer>
                                <Button type="submit" color="primary" loading={bLoading} block>
                                    Sign In
                                </Button>
                            </Form.Footer>

                        </Form>
                    </div>
                </div>
            </div>
        );

    }

}

const mapStateToProps = (reducer ) => {

    const { user } = reducer;

    return { user}

};


export default connect (mapStateToProps, {
    updateUser
}) (SignIn);
