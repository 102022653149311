import React from 'react';
import { connect } from 'react-redux';
import {
    Page,
    Table,
    Card,
    Button,
    FormTextInput,
    Form
} from 'tabler-react';
import Modal from 'react-responsive-modal';
import cogoToast from "cogo-toast";
import { v4 as uuidv4 } from 'uuid';

import { startRequest, stopRequest, subscribeDevice, unsubscribeDevice } from 'mqtt-helper';
import { setDevices } from 'store/actions';

import "tabler-react/dist/Tabler.css";
import './index.scss'
import moment from 'moment';

class DeviceList extends React.Component {

    constructor (props) {

        super (props);

        this.state = {
            show_new_device_modal: false,
            show_update_device_modal: false,
            show_delete_device_modal: false,

            regID: "",
            name: "",
            amount: ""
        }
    }

    addNewDevice = (event) => {
        event.preventDefault();

        const { regID, name, amount } = this.state;
        const { devices, mqtt_server } = this.props;

        if (regID.trim() === '') {
            cogoToast.error('Please add regID.');
            return;
        }

        if (devices.filter(device => device.regID === regID).length > 0) {
            cogoToast.error('regID was already registered.');
            return;
        }

        devices.push({ regID, name, amount: Number(amount) });

        this.props.setDevices(JSON.parse(JSON.stringify(devices)));

        this.closeNewDeviceModal();

        subscribeDevice(mqtt_server.basepath, { regID }, (error) => {
            if (error) {
                cogoToast.error(error);
            }
        });
    }

    updateDevice = (event) => {
        event.preventDefault();

        const { regID, name, amount } = this.state;
        const { devices, mqtt_server } = this.props;

        let filteredDevices = devices.filter(device => device.regID === regID);
        if (filteredDevices.length > 0 && filteredDevices[0].regID !== this.selectedDevice.regID) {
            cogoToast.error('regID was already registered.');
            return;
        }

        const _devices = [];
        devices.forEach(device => {
            if (device.regID === this.selectedDevice.regID) {

                if (this.selectedDevice.regID !== regID || this.selectedDevice.amount !== Number(amount)) {
                    unsubscribeDevice(mqtt_server.basepath, device, () => {});
                    subscribeDevice(mqtt_server.basepath, {regID: regID, amount: Number(amount)}, () => {});
                    _devices.push({ regID, name, amount: Number(amount) });

                } else {
                    _devices.push(device);
                }

            } else {
                _devices.push(device);
            }
        });

        this.props.setDevices(_devices);
        this.closeUpdateDeviceModal();
    }

    doDelete = () =>  {
        const { devices, mqtt_server } = this.props;

        const _devices = [];
        devices.forEach(device => {
            if (device.regID !== this.selectedDevice.regID) {
                _devices.push(device);
            }
        });

        this.props.setDevices(_devices);
        this.closeDeleteDeviceModal();

        unsubscribeDevice(mqtt_server.basepath, this.selectedDevice, (error) => {
            if (error) {
                cogoToast.error(error);
            }
        });
    }

    editDevice = (event, device) => {
        event.stopPropagation()

        this.selectedDevice = device;

        this.setState({
            regID: device.regID,
            name: device.name,
            amount: device.amount,
            show_update_device_modal: true
        });

    }

    deleteDevice = (event, device) => {
        event.stopPropagation()

        this.selectedDevice = device;
        this.setState({ show_delete_device_modal: true });
    }

    showNewDeviceModal = () => {
        this.setState({ 
            show_new_device_modal: true
        });
    }
    
    closeNewDeviceModal = () => {
        this.setState({ show_new_device_modal: false });
    }

    closeUpdateDeviceModal = () => {
        this.setState({ show_update_device_modal: false });
    }

    closeDeleteDeviceModal = () => {
        this.setState({ show_delete_device_modal: false });
    }

    updateForm = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    startRequest = (device) => {
        const { mqtt_server, devices } = this.props;
        
        startRequest(mqtt_server.basepath, device, uuidv4(), (err, requestId) => {
            if (err) {
                cogoToast.error(err);
                return;

            } else {
                device.started = true;
                device.requestId = requestId;
                
                this.props.setDevices(JSON.parse(JSON.stringify(devices)));
            }
        });
    }

    stopRequest = (device) => {
        const { mqtt_server, devices } = this.props;
        
        stopRequest(mqtt_server.basepath, device, device.requestId, (err) => {
            if (err) {
                cogoToast.error(err);
                return;

            } else {
                device.started = false;
                device.requestId = '';
                
                this.props.setDevices(JSON.parse(JSON.stringify(devices)));
            }
        });
    }

    render () {
        const { show_new_device_modal, show_update_device_modal, show_delete_device_modal, regID, name, amount } = this.state;

        const { devices, transactions } = this.props;

        const options = (
            <React.Fragment>

                <Button.List>

                    <Button
                        icon="plus"
                        color="primary"
                        onClick={this.showNewDeviceModal}
                    >New Device</Button>

                </Button.List>
                
            </React.Fragment>
        );

        return (

            <div id="devices">
                <Page.Header
                    title="Devices"
                    options={devices.length < 4?options:null}
                />

                <Card>
                    <Card.Body>
                        <Table
                            responsive
                            highlightRowOnHover
                            hasOutline
                            verticalAlign="center"
                            cards
                            className="text-nowrap">

                            <Table.Header>
                                <Table.Row>
                                    <Table.ColHeader>Reg ID</Table.ColHeader>
                                    <Table.ColHeader>Device Name</Table.ColHeader>
                                    <Table.ColHeader>Amount</Table.ColHeader>
                                    <Table.ColHeader>Last Transaction</Table.ColHeader>
                                    <Table.ColHeader>Actions</Table.ColHeader>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {devices.map((device, index) => (
                                    <Table.Row key={ index }>
                                        <Table.Col>{ device.regID }</Table.Col>
                                        <Table.Col>{ device.name }</Table.Col>
                                        <Table.Col>{ device.amount }</Table.Col>
                                        <Table.Col>{ device.last_transaction }</Table.Col>
                                        <Table.Col>
                                            <Button.List>
                                                {!device.started?
                                                <Button color="primary" size="sm" icon="play" disabled={!device.enabled} onClick={() => this.startRequest(device)} />
                                                :
                                                <Button color="primary" size="sm" icon="pause" onClick={() => this.stopRequest(device)} />
                                                }
                                                <Button color="orange" size="sm" icon="edit" onClick={(event) => this.editDevice(event, device)} />
                                                <Button color="danger" size="sm" icon="trash-2" onClick={(event) => this.deleteDevice(event, device)} />
                                            </Button.List>
                                        </Table.Col>
                                    </Table.Row>
                                ))}
                                {devices.length === 0 &&
                                <Table.Row>
                                    <Table.Col colSpan="5" alignContent="center"><Form.Label>No Devices</Form.Label></Table.Col>
                                </Table.Row>
                                }
                            </Table.Body>
                        </Table>
                    </Card.Body>
                </Card>

                <Page.Header
                    title="Transaction Log"
                />

                <Card className="transaction-log">
                    <Card.Body>
                        <Table
                            responsive
                            highlightRowOnHover
                            hasOutline
                            verticalAlign="center"
                            cards
                            className="text-nowrap">

                            <Table.Header>
                                <Table.Row>
                                    <Table.ColHeader>Topic</Table.ColHeader>
                                    <Table.ColHeader>Message</Table.ColHeader>
                                    <Table.ColHeader>Time</Table.ColHeader>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {transactions.map((transaction, index) => (
                                    <Table.Row key={ index }>
                                        <Table.Col>{ transaction.topic }</Table.Col>
                                        <Table.Col>{ transaction.message }</Table.Col>
                                        <Table.Col>{ moment(transaction.date).format('YYYY-MM-DD HH:mm:ss') }</Table.Col>
                                    </Table.Row>
                                ))}
                                {transactions.length === 0 &&
                                <Table.Row>
                                    <Table.Col colSpan="3" alignContent="center"><Form.Label>No Transaction</Form.Label></Table.Col>
                                </Table.Row>
                                }
                            </Table.Body>
                        </Table>
                    </Card.Body>
                </Card>
                

                <Modal open={show_delete_device_modal} onClose={this.closeDeleteDeviceModal} little 
                    showCloseIcon={false} closeOnEsc={false} closeOnOverlayClick={false}>
                    <h2>Confirmation</h2>
                    <p>
                        Are you sure to delete this device? This action can't be undone.
                    </p>

                    <div className="d-flex align-items-center justify-content-between mt-5">
                        <Button color="secondary" onClick={this.closeDeleteDeviceModal}>Cancel</Button>
                        <Button color="danger" onClick={this.doDelete}>Yes, delete</Button>
                    </div>
                </Modal>

                <Modal open={show_new_device_modal} onClose={this.closeNewDeviceModal} little 
                    showCloseIcon={false} closeOnEsc={false} closeOnOverlayClick={false}>
                    <h2>Add New Device</h2>
                    <p>
                        Please input device details and click 'Submit'. <br />
                        You can add up to 4 devices.
                    </p>

                    <Form onSubmit={this.addNewDevice}>

                        <FormTextInput
                            name="regID"
                            label="Reg ID"
                            type="text"
                            placeholder="Enter Reg ID"
                            onChange={this.updateForm}
                            required />

                        <FormTextInput
                            name="name"
                            label="Device Name"
                            type="text"
                            placeholder="Enter device name"
                            onChange={this.updateForm}
                            />

                        <div className="form-group">
                            <label className="form-label">Amount</label>
                            <input type="number" name="amount" className="form-control" placeholder="Enter amount" 
                                min="0" step=".01" required onChange={this.updateForm} />
                        </div>
                        
                        <div className="d-flex align-items-center justify-content-between mt-5">
                            <Button type="button" color="secondary" onClick={this.closeNewDeviceModal}>Cancel</Button>
                            <Button color="primary">Add Device</Button>
                        </div>
                    </Form>
                </Modal>

                <Modal open={show_update_device_modal} onClose={this.closeUpdateDeviceModal} little 
                    showCloseIcon={false} closeOnEsc={false} closeOnOverlayClick={false}>
                    <h2>Update Device</h2>
                    <p>
                        Please input device details and click 'Submit'. <br />
                    </p>

                    <Form onSubmit={this.updateDevice}>

                        <FormTextInput
                            name="regID"
                            label="Reg ID"
                            type="text"
                            value={regID}
                            placeholder="Enter Reg ID"
                            onChange={this.updateForm}
                            required />

                        <FormTextInput
                            name="name"
                            label="Device Name"
                            type="text"
                            value={name}
                            placeholder="Enter device name"
                            onChange={this.updateForm}
                            />

                        <div className="form-group">
                            <label className="form-label">Amount</label>
                            <input type="number" name="amount" className="form-control" placeholder="Enter amount" 
                                min="0" step=".01" value={amount} required onChange={this.updateForm} />
                        </div>

                        <div className="d-flex align-items-center justify-content-between mt-5">
                            <Button type="button" color="secondary" onClick={this.closeUpdateDeviceModal}>Cancel</Button>
                            <Button color="primary">Update Device</Button>
                        </div>
                    </Form>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (reducer ) => {
    const { user, devices, transactions, mqtt_server } = reducer;

    return { user, devices, transactions, mqtt_server }
};


export default connect (mapStateToProps, {
    setDevices
}) (DeviceList);
